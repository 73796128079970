* {
  image-rendering: pixelated;
}

#current-run-game-tab {
  z-index: 100;
  background-position: -83px;
  width: 621px;
  height: 145px;
  position: absolute;
  top: 490px;
}

#current-run-game-tab .info-bg {
  background-image: url("gametab-campi.67d97f29.png");
  background-position: 0 59px;
  background-repeat: no-repeat;
  width: 621px;
  height: 145px;
  position: absolute;
}

#current-run-game-tab .title {
  color: #0f2e0d;
  text-align: center;
  opacity: 0;
  text-transform: uppercase;
  letter-spacing: .4px;
  width: 100%;
  height: 60px;
  font-size: 42px;
  position: absolute;
  top: 5px;
  overflow: hidden;
}

#current-run-game-tab .category {
  color: #f3f3f3;
  text-align: center;
  opacity: 0;
  width: 456px;
  height: 19px;
  font-family: edit-undo;
  font-size: 20px;
  position: absolute;
  top: 72px;
  overflow: hidden;
}

#current-run-game-tab .elapsed {
  color: #f3f3f3;
  text-align: center;
  opacity: 0;
  width: 154px;
  height: 19px;
  font-family: edit-undo;
  font-size: 20px;
  position: absolute;
  top: 72px;
  left: 467px;
}

#current-run-game-tab .platform {
  color: #f3f3f3;
  text-align: center;
  opacity: 0;
  width: 490px;
  height: 19px;
  font-family: edit-undo;
  font-size: 20px;
  position: absolute;
  top: 114px;
}

#current-run-game-tab .year {
  color: #f3f3f3;
  text-align: center;
  opacity: 0;
  width: 120px;
  height: 19px;
  font-family: edit-undo;
  font-size: 20px;
  position: absolute;
  top: 114px;
  left: 501px;
}
/*# sourceMappingURL=repo.c5d7cfc6.css.map */
